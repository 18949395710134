<template>
  <div class="col-xl-12" v-show="userRole == '3' && serviceTy == '2'">
    <div class="card">
      <div class="card-header card-no-border">
        <div class="header-top">
          <h5>돌봄 받는자</h5>

          <div class="card-header-right-icon">
            <DropDown1 />
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="customer-table table-responsive currency-table">
          <div class="col-xxl-12 col-ed-12 col-xl-12 col-sm-12 box-col-12">
            <EasyDataTable
              table-class-name="custom-data-table"
              buttons-pagination
              :search-value="searchValue"
              :search-field="'targetNm'"
              :filter-options="filterOptions"
              :headers="Headers"
              :items="flatItems"
              show-index
            >
              <template #item-careTargetNm="{ careTargetNm, careTargetNm_label }">
                <td :data-label="careTargetNm_label">
                  {{ careTargetNm }}
                </td>
              </template>

              <template #item-birthDt="{ birthDt, birthDt_label }">
                <td :data-label="birthDt_label">
                  {{ birthDt }}
                </td>
              </template>
              <template #item-gender="{ gender, gender_label }">
                <td :data-label="gender_label">
                  {{ formatGender(gender) }}
                </td>
              </template>
              <template #item-pressure="{ pressure, pressure_label }">
                <td :data-label="pressure_label">
                  {{ pressure }}
                </td>
              </template>
              <template #item-pulse="{ pulse, pulse_label }">
                <td :data-label="pulse_label">
                  {{ pulse }}
                </td>
              </template>

              <template #item-bodyTemperature="{ bodyTemperature, bodyTemperature_label }">
                <td :data-label="bodyTemperature_label">
                  {{ bodyTemperature }}
                </td>
              </template>

              <template #item-oxygenSaturation="{ oxygenSaturation, oxygenSaturation_label }">
                <td :data-label="oxygenSaturation_label">
                  {{ oxygenSaturation }}
                </td>
              </template>

              <template #item-steps="{ steps, steps_label }">
                <td :data-label="steps_label">
                  {{ steps }}
                </td>
              </template>

              <template #item-guardianNm="{ gNm, gNm_label }">
                <td :data-label="gNm_label">
                  {{ gNm }}
                </td>
              </template>
              <template #item-gRelation="{ gRelation, gRelation_label }">
                <div v-if="gRelation == '1'">
                  <td :data-label="gRelation_label">배우자</td>
                </div>
                <div v-if="gRelation == '2'">
                  <td :data-label="gRelation_label">자녀</td>
                </div>
                <div v-if="gRelation == '3'">
                  <td :data-label="gRelation_label">형제</td>
                </div>
                <div v-if="gRelation == '4'">
                  <td :data-label="gRelation_label">친척</td>
                </div>
                <div v-if="gRelation == '5'">
                  <td :data-label="gRelation_label">기타</td>
                </div>
              </template>
              <template #item-careUsers="{ careUsers, careUsers_label }">
                <td :data-label="careUsers_label">
                  <span :class="getRandomBadgeClass()" style="font-size: 0.8rem">
                    {{ formatCareUsers(careUsers) }}
                  </span>
                </td>
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</template>
<style>
.custom-data-table {
  overflow: hidden; /* 또는 overflow: visible; */
}
</style>
<script>
import DropDown1 from "../base/dropdown/DropDown1.vue";
import api from "@/api.js";
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
import * as criteria from "@/data/criteriaData.js";
import { ref, computed } from "vue";
import { targetMonitoringHeader } from "@/data/tableHeaders.js";
import { formatTime, formatDate, formatDateNotHyphen } from "@/utils/dateUtils.js";
import { formatGender, getLoginPrjId, getRandomBadgeClass, formatCareUsers, getLoginId, getLoginUserRole } from "@/utils/common.js";
export default {
  // props: {
  //   prjId: {
  //     type: String,
  //     required: true,
  //   },
  // },
  components: {
    DropDown1,
  },
  data() {
    return {
      Headers: targetMonitoringHeader,
      careTargetId: "",
      formatDateNotHyphen,
      formatDate,
      formatGender,
      formatCareUsers,
      items: [],
      loginId: getLoginId(),
      userRole: getLoginUserRole(),
    };
  },
  methods: {
    getRandomBadgeClass,
    fetchTagets(serviceTy = "", userRole = "", prjId = "") {
      let url = `/target/monitoring?dt=${formatDateNotHyphen(this.dt)}&serviceTy=${serviceTy}&userRole=${userRole}&prjId=${prjId}`;
      if (this.userRole == "3") url = url + `&userId=${this.loginId}`;
      api
        .get(url)
        .then((response) => {
          // console.log("careUserNm", careUserNm);
          this.items = response.data.map((item) => ({
            ...item,
            data: item.data,
          }));
          // console.log("this.items", this.items);
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "돌봄받는자 현황을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
  },

  mounted() {
    let user = JSON.parse(localStorage.getItem("User"));
    let serviceTy = user.serviceTy;
    let userRole = user.userRole;
    this.serviceTy = serviceTy;
    this.userRole = userRole;
    this.prjId = getLoginPrjId();
    this.fetchTagets(this.serviceTy, this.userRole, this.prjId);
  },
  computed: {
    flatItems() {
      return this.items.map((item) => {
        return {
          ...item,

          ...(item.data || {}), // 데이터가 있는 경우 덮어씌우기
        };
      });
    },
  },
  setup() {
    const dt = ref(new Date());
    return { dt };
  },
};
</script>
