<template>
  <div class="header-wrapper row m-0">
    <!-- <SearchBar class="col-auto"/>  -->
    <Logo />
    <div class="left-header col-xxl-10 col-xl-10 col-lg-8 col-md-7 col-sm-1 p-0">
      <swiper
        v-if="Items && Items.length > 0"
        :slidesPerView="1"
        :autoplay="{ delay: 4500, disableOnInteraction: false }"
        :modules="modules"
        class="notification-slider"
        direction="vertical"
      >
        <swiper-slide class="h-100" id="abc" v-for="item in Items" :key="item.id">
          <div class="d-flex h-100">
            <h6 class="mb-0 f-w-400 clickable" @click="topEditNotice(item.noticeId)">
              <span :class="[{ 'badge badge-secondary': item.importance == '3' }, { 'badge badge-warning': item.importance == '2' }]">{{ formatImportance(item.importance) }}</span>
              &nbsp;&nbsp; {{ item.title }} &nbsp;&nbsp; {{ item.userNm }} &nbsp;&nbsp; {{ formatTime(item.dt) }}
            </h6>
          </div>
        </swiper-slide>
      </swiper>
      <div v-else class="d-flex justify-content-center align-items-center h-100">
        <h6 class="text-muted">조회된 공지사항이 없습니다</h6>
      </div>
    </div>

    <div class="nav-right col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-10 pull-right right-header p-0 ms-0">
      <ul class="nav-menus">
        <li class="language-nav">
          <Language />
        </li>

        <Notifications />

        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import Bookmark from "../bookmark";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Notifications from "./notifications.vue";
import Mode from "./mode";
import Cart from "./cart";
import Profile from "./profile";
import Logo from "./logo.vue";
/* import SearchBar from "./search"; */
import { ref, computed } from "vue";
import { noticeListHeader } from "@/data/tableHeaders.js";
import { getLoginId, formatImportance, getLoginUserRole } from "@/utils/common";
import { formatTime, formatDate, formatDateNotHyphen } from "@/utils/dateUtils.js";

export default {
  //   name: 'Search',
  components: {
    Bookmark,
    Notifications,
    Mode,
    Cart,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
    /*     SearchBar, */
  },
  data() {
    return {
      scrollDuration: 1000, // 스크롤 지속 시간 (밀리초)
      scrollInterval: null,

      formatDate,
      formatTime,
      formatImportance,
      formatDateNotHyphen,
      Headers: noticeListHeader,
      Items: [],
      bookmark: false,
    };
  },
  mounted() {
    this.fetchNotice();
    // this.loadNoticeDetails(this.$route.params.noticeId);
  },
  // computed: {
  //   sortedItems() {
  //     return this.Items.sort((a, b) => {
  //       return b.importance - a.importance;
  //     });
  //   },
  // },

  methods: {
    fetchNotice() {
      const url = `/notice?noticeStart=${formatDateNotHyphen(this.noticeStart)}&noticeEnd=${formatDateNotHyphen(this.noticeEnd)}`;
      api
        .get(url)
        .then((response) => {
          this.Items = response.data.sort((a, b) => {
            // Sort by importance
            return b.importance - a.importance;
          });
          // console.log("공지사항 목록을 불러왔습니다.", this.Items);
        })
        .catch((error) => {
          console.error("공지사항 목록을 불러오는 중 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "공지사항 목록을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },

    topEditNotice(noticeId) {
      //공지사항 상세
      //this.$router.push({ name: "noticeEdit", params: { noticeId: noticeId } });
      //this.$store.dispatch('fetchNoticeDetails', noticeId).then(() => {
      this.$router.push({ name: "noticeEdit", params: { noticeId: noticeId } });
      //});
      // console.log("noticeId test2 : ", noticeId);
    },
  },
  setup() {
    const today = new Date();
    const noticeStart = ref(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()));
    const noticeEnd = ref(new Date());
    return {
      modules: [Autoplay],
      noticeStart,
      noticeEnd,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer; /* 마우스를 올렸을 때 커서를 손가락 모양으로 변경 */
}

.scroll-container {
  position: relative;
  height: 40px; /* 컨테이너 높이 설정 (하나의 항목 높이와 동일) */
  overflow: hidden;
}

.scroll-content {
  display: flex;
  flex-direction: column;
  animation: scroll linear infinite;
  animation-duration: 10s; /* 스크롤 전체 지속 시간 */
}

.scroll-item {
  height: 40px; /* 각 항목의 높이 설정 */
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
}
.vue3-easy-data-table__header,
.vue3-easy-data-table__body {
  display: none;
}
/* .easydatatable thead {
  display: none;
} */
/* 애니메이션 정의 */
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70%);
  }
}
</style>
