<template>
  <div id="sidebar-menu">
    <ul
      class="sidebar-links custom-scrollbar"
      id="myDIV"
      :style="[
        layoutobject.split(' ').includes('horizontal-wrapper')
          ? layout.settings.layout_type == 'rtl'
            ? { 'margin-right': margin + 'px' }
            : { 'margin-left': margin + 'px' }
          : { margin: '0px' },
      ]"
    >
      <li class="back-btn">
        <router-link to="/">
          <img class="img-fluid" src="../../assets/images/logo/logo-icon.png" alt="" />
        </router-link>
        <div class="mobile-back text-end"><span>Back</span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
      </li>

      <!-- 메뉴 항목 렌더링 -->
      <li v-for="(menuItem, index) in filteredMenuItems" :key="index" class="sidebar-list" :class="{ 'sidebar-main-title': menuItem.type == 'headtitle' && menuItem.isShow }">
        <!-- 헤드 타이틀 -->
        <div v-if="menuItem.isShow && menuItem.type === 'headtitle'">
          <h6 class="lan-1" style="color: #457fb8">{{ menuItem.headTitle1 }}</h6>
        </div>

        <!-- 서브메뉴 또는 링크 메뉴 렌더링 -->
        <template v-if="menuItem.isShow && (menuItem.type === 'sub' || menuItem.type === 'link')">
          <a href="javascript:void(0)" class="sidebar-link sidebar-title" :class="{ active: menuItem.active }" @click="setNavActive(menuItem)">
            <svg class="stroke-icon">
              <use :xlink:href="getIconHref(menuItem.icon)"></use>
            </svg>
            <svg class="fill-icon">
              <use :xlink:href="getIconHref(menuItem.iconf)"></use>
            </svg>
            <span class="lan-3"> {{ $t(menuItem.title) }} </span>
            <div class="according-menu" v-if="menuItem.children">
              <!-- <i class="fa fa-angle-right pull-right"></i> -->
            </div>
          </a>
          <!-- 서브메뉴가 있을 경우 하위 항목 렌더링 -->
          <ul v-if="menuItem.children" class="sidebar-submenu" :class="{ 'menu-open': menuItem.active }" :style="{ display: menuItem.active ? '' : 'none' }">
            <li v-for="(childrenItem, index) in menuItem.children" :key="index">
              <router-link v-if="childrenItem.isShow" :to="childrenItem.path" class="lan-4" :class="{ active: childrenItem.active }" @click="setNavActive(childrenItem, menuItem)">
                {{ childrenItem.title }}
                <!-- <i class="fa fa-angle-right pull-right mt-1" v-if="childrenItem.children"></i> -->
              </router-link>
            </li>
          </ul>
        </template>

        <!-- 외부 링크 메뉴 -->
        <a v-if="menuItem.type === 'extLink'" :href="menuItem.path" class="sidebar-link sidebar-title" @click="setNavActive(menuItem)">
          <svg class="stroke-icon">
            <use :xlink:href="getIconHref(menuItem.icon)"></use>
          </svg>
          <svg class="fill-icon">
            <use :xlink:href="getIconHref(menuItem.iconf)"></use>
          </svg>
          <span> {{ menuItem.title }} </span>
          <!-- <i class="fa fa-angle-right pull-right" v-if="menuItem.children"></i> -->
        </a>

        <!-- 외부 탭 링크 메뉴 -->
        <a v-if="menuItem.type === 'extTabLink'" :href="menuItem.path" target="_blank" class="sidebar-link sidebar-title" @click="setNavActive(menuItem)">
          <svg class="stroke-icon">
            <use :xlink:href="getIconHref(menuItem.icon)"></use>
          </svg>
          <svg class="fill-icon">
            <use :xlink:href="getIconHref(menuItem.iconf)"></use>
          </svg>
          <span> {{ menuItem.title }} </span>
          <!-- <i class="fa fa-angle-right pull-right" v-if="menuItem.children"></i> -->
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { layoutClasses } from "../../constants/layout";
import { getLoginUserRole } from "@/utils/common.js";

export default {
  name: "Navmenu",
  data() {
    return {
      layoutobj: {},
      userRole: getLoginUserRole(),
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
    layoutobject: {
      get() {
        return this.getLayoutObject(this.layout.settings.layout);
      },
      set(layout) {
        this.layoutobj = this.getLayoutObject(layout);
        return this.layoutobj;
      },
    },
    //사용자 권한에 따른 메뉴 필터링
    filteredMenuItems() {
      const hasAccess = (menuItem) => {
        //0 : 관리자, 2 : 기관관리자, 3 : 보호사, t : 임시
        // 메뉴 항목의 roles에 현재 userRole이 포함되어 있는지 확인
        return !menuItem.roles || menuItem.roles.includes(this.userRole);
      };

      const updateVisibility = (menuItem) => {
        // 현재 항목이 접근 가능한지 확인
        menuItem.isShow = hasAccess(menuItem);

        // children이 있는 경우, 자식 메뉴의 표시 여부도 업데이트
        if (menuItem.children) {
          menuItem.children.forEach(updateVisibility);
          // 자식 중 하나라도 표시 가능한 경우, 부모 메뉴도 표시
          menuItem.isShow = menuItem.isShow || menuItem.children.some((child) => child.isShow);
        }
        return menuItem;
      };
      // 모든 menuItems에 대해 표시 여부 업데이트
      return this.menuItems.map(updateVisibility);
    },
  },
  watch: {
    width() {
      this.handleResize();
    },
  },
  created() {
    this.initializeMenu();
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadUserData();
    //this.activateDashboard();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // 사용자 데이터 로드
    loadUserData() {
      const user = JSON.parse(localStorage.getItem("User"));
      if (user) {
        this.userRole = user.userRole;
        if (localStorage.getItem("isInfoRead") != "true" && this.userRole == "t") {
          this.$swal({
            icon: "info",
            title: "임시 권한 안내",
            html: "회원 승인이 완료되지 않아 일부 메뉴가 제한됩니다.<br/> 승인을 위해 관리자에게 문의해 주세요.",
            type: "info",
          }).then(function () {
            //안내 메세지 읽은 경우
            localStorage.setItem("isInfoRead", true);
          });
        }
        // this.fetchMembers(user.username);
      }
      //alert("this.userRole. "+this.userRole);
    },
    // 레이아웃 객체 설정
    getLayoutObject(layout) {
      const layoutData = layoutClasses.find((item) => Object.keys(item).pop() === layout);
      return layoutData ? layoutData[layout] : {};
    },
    // 메뉴 초기화
    initializeMenu() {
      this.layoutobject = this.getLayoutObject(this.layout.settings.layout);
      setTimeout(() => {
        const elmnt = document.getElementById("myDIV");
        this.$store.state.menu.menuWidth = elmnt.offsetWidth;
        const isOverflow = this.$store.state.menu.menuWidth > window.innerWidth;
        this.$store.state.menu.hideRightArrow = !isOverflow;
        this.$store.state.menu.hideLeftArrowRTL = isOverflow;
      }, 500);
    },
    // 아이콘 경로 생성
    getIconHref(iconName) {
      return require(`@/assets/svg/icon-sprite.svg`) + `#${iconName}`;
    },
    // 메뉴 활성화 설정
    setNavActive(item, parent = null) {
      // 부모 메뉴를 닫는 로직을 추가할 때, 자식 메뉴를 클릭할 때는 부모 메뉴를 닫지 않도록 수정
      if (!parent) {
        // 부모 메뉴에 대한 활성화 상태 처리 (다른 부모 메뉴 닫기)
        this.menuItems.forEach((menuItem) => {
          if (menuItem !== item && menuItem.active) {
            menuItem.active = false;
          }

          // 자식 메뉴가 있는 경우 자식 메뉴의 상태도 검사
          if (menuItem.children) {
            menuItem.children.forEach((child) => {
              if (child !== item && child.active) {
                child.active = false;
              }
            });
          }
        });
      } else {
        // 자식 메뉴를 클릭한 경우, 동일한 부모 메뉴 내의 다른 자식 메뉴들을 비활성화
        parent.children.forEach((child) => {
          if (child !== item && child.active) {
            child.active = false; // 다른 자식 메뉴 비활성화
          }
        });
      }

      // 부모 메뉴를 클릭한 경우 활성화 상태를 토글함
      if (item.children) {
        item.active = !item.active;
      } else {
        // 자식 메뉴일 경우, 동일한 자식 메뉴를 선택해도 아무 동작도 하지 않음
        if (!item.active) {
          item.active = true;
        } else {
          return; // 이미 활성화된 자식 메뉴는 아무 작업도 하지 않음
        }
      }

      // Compact Sidebar일 때, 오버레이 상태 업데이트
      if (this.layoutobject.split(" ").includes("compact-sidebar") && window.innerWidth > 991) {
        if (this.menuItems.some((menuItem) => menuItem.active === true)) {
          this.$store.state.menu.activeoverlay = true;
        } else {
          this.$store.state.menu.activeoverlay = false;
        }
      }

      // 부모 메뉴에 path가 있는 경우 페이지 이동
      if (item.path && !item.children) {
        this.$router.push(item.path).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
      }
    },
    activateDashboard() {
      // title이 'Dashboard'인 항목을 찾아 활성화
      const dashboardItem = this.menuItems.find((item) => item.title === "Dashboard");
      if (dashboardItem) {
        this.setNavActive(dashboardItem);
      }
    },
    // 메뉴 오버레이 상태 업데이트
    updateOverlayState() {
      const isCompact = this.layoutobject.split(" ").includes("compact-sidebar");
      if (isCompact && window.innerWidth > 991) {
        const hasActiveItem = this.menuItems.some((menuItem) => menuItem.active);
        this.$store.state.menu.activeoverlay = hasActiveItem;
      }
    },
    // 리사이즈 핸들러
    handleResize() {
      this.$store.state.menu.width = window.innerWidth - 450;
    },
  },
};
</script>
