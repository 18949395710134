import { createRouter, createWebHistory } from "vue-router";
import Body from "../components/body";
import Default2 from "../pages/dashboard/defaultPage.vue";
import Default from "../pages/dashboard/main.vue";

/* 
//Auth 
import login from "../auth/login";
import Register_1 from "../auth/register_1";
import Register_2 from "../auth/register_2";
import Register_3 from "../auth/register_3"; 
// user
import userProfile from "../pages/users/profile/userProfile.vue";
import userList from "../pages/users/userList/userList.vue";
import userEdit from "../pages/users/edit/userEdit.vue";
// project
import projectList from "../pages/projects/projectList/projectList.vue";
import projectEdit from "../pages/projects/edit/projectEdit.vue";
// robot
import robotList from "../pages/robots/robotList/robotList.vue";
import robotEdit from "../pages/robots/edit/robotEdit.vue";
// target
import targetList from "../pages/targets/targetList/targetList.vue";
import targetEdit from "../pages/targets/targetList/targetEdit.vue";
// error
import Error400 from "../pages/error/error400";
import Error401 from "../pages/error/error401";
import Error403 from "../pages/error/error403";
import Error404 from "../pages/error/error404";
import Error500 from "../pages/error/error500";
import Error503 from "../pages/error/error503"; 
*/

//async 로 변경
// Auth
const login = () => import("../auth/login");
const Register_1 = () => import("../auth/register_1");
const Register_2 = () => import("../auth/register_2");
const Register_3 = () => import("../auth/register_3");
//DashBoard
const main = () => import("../pages/dashboard/main");
const targetListCard = () => import("../pages/dashboard/targetListCard");
const targetListGroup = () => import("../pages/dashboard/targetListGroup");
const targetListCareGroup = () => import("../pages/dashboard/targetListCareGroup");

// user
const userProfile = () => import("../pages/users/profile/profile");
const userList = () => import("../pages/users/userList/userList");
const userEdit = () => import("../pages/users/edit/userEdit");
// project
const projectList = () => import("../pages/projects/projectList/projectList");
const projectEdit = () => import("../pages/projects/edit/projectEdit");
const projectArea = () => import("../pages/projects/area/treeView");
// robot
const robotList = () => import("../pages/robots/robotList/robotList");
const robotEdit = () => import("../pages/robots/edit/robotEdit");
// target
const targetList = () => import("../pages/targets/targetList/targetList");
const targetDetail = () => import("../pages/targets/edit/targetDetail");
//careGiver
const careGiverList = () => import("../pages/careGiver/careGiverList/careGiverList");
const careGiverEdit = () => import("../pages/careGiver/edit/careGiverEdit");
//notice
const noticeList = () => import("../pages/notice/noticeList/noticeList");
const noticeEdit = () => import("../pages/notice/edit/noticeEdit");
// error
const Error400 = () => import("../pages/error/error400");
const Error401 = () => import("../pages/error/error401");
const Error403 = () => import("../pages/error/error403");
const Error404 = () => import("../pages/error/error404");
const Error500 = () => import("../pages/error/error500");
const Error503 = () => import("../pages/error/error503");

//works
const healthRecord = () => import("../pages/works/healthRecord");
const careTargetBaseInfo = () => import("../pages/works/careTargetBaseInfo");
const careSchedule = () => import("../pages/works/careSchedule");
//monitoring
const projectMonitoring = () => import("../pages/monitor/projectMonitor/projectMonitor");
const caregiverMonitoring = () => import("../pages/monitor/caregiverMonitor/caregiverMonitor");
const weeklyReports = () => import("../pages/monitor/caregiverMonitor/weeklyReports");
const caregiverDetail = () => import("../pages/monitor/caregiverMonitor/caregiverDetailMonitor");
const targetMonitoring = () => import("../pages/monitor/targetMonitor/targetMonitor");
const targetDetailMonitoring = () => import("../pages/monitor/targetMonitor/targetDetailMonitor");
const robotMonitoring = () => import("../pages/monitor/robotMonitor/robotMonitor");
const robotMonitorDetail = () => import("../pages/monitor/robotMonitor/robotMonitorCard");
const robotMonitorDetailCard = () => import("../pages/monitor/robotMonitor/robotMonitorDetailCard");
const routes = [
  {
    path: "/",
    name: "Root",
    redirect: (to) => {
      const loggedIn = localStorage.getItem("accessToken");

      if (loggedIn) {
        // Redirect to the dashboard if logged in
        return "/dashboard";
      } else {
        // Redirect to the login page if not logged in
        return "/auth/login";
      }
    },
  },

  {
    path: "/:pathMatch(.*)*", // 정의되지 않은 모든 경로에 대해 매칭
    redirect: "/error-404", // 여기로 리다이렉트
  },
  {
    path: "/error-400",
    name: "Error400",
    component: Error400,
    meta: {
      title: "Error400 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/error-401",
    name: "Error401",
    component: Error401,
    meta: {
      title: "Error401 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/error-403",
    name: "Error403",
    component: Error403,
    meta: {
      title: "Error403 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/error-404",
    name: "Error404",
    component: Error404,
    meta: {
      title: "Error404 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/error-500",
    name: "Error500",
    component: Error500,
    meta: {
      title: "Error500 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/error-503",
    name: "Error503",
    component: Error503,
    meta: {
      title: "Error503 | Cuba - Premium Admin Template",
    },
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: login,
        meta: {
          title: " login | Care Place",
        },
      },
      {
        path: "register_1",
        name: "register 1",
        component: Register_1,
        meta: {
          title: " 가입확인 | Care Place",
        },
      },
      {
        path: "register_2/:sendData?",
        name: "register 2",
        component: Register_2,
        meta: {
          title: " 기본정보 | Care Place",
        },
        props: true,
      },
      {
        path: "register_3/:sendData?",
        name: "register 3",
        component: Register_3,
        meta: {
          title: " 기관설정 | Care Place",
        },
        props: true,
      },
    ],
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: main,
        meta: {
          title: "Dashboard | Care Place",
        },
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    children: [
      {
        path: "profile",
        name: "profile",
        component: userProfile,
        meta: {
          title: "내정보 | Care Place",
        },
      },
      {
        path: "edit/:userId/:prjFilter?",
        name: "userEdit",
        component: userEdit,
        meta: {
          title: "사용자관리 | Care Place",
        },
      },
      {
        path: "userList/:prjId?",
        name: "userList",
        component: userList,
        meta: {
          title: "사용자관리 | Care Place",
        },
      },
    ],
  },
  {
    path: "/projects",
    component: Body,
    children: [
      {
        path: "edit/:prjId",
        name: "projectEdit",
        component: projectEdit,
        meta: {
          title: "기관관리 | Care Place",
        },
      },
      {
        path: "projectList",
        name: "projectList",
        component: projectList,
        meta: {
          title: "기관관리 | Care Place",
        },
      },
      {
        path: "projectArea/:prjId",
        name: "projectArea",
        component: projectArea,
      },
    ],
  },
  {
    path: "/robots",
    component: Body,
    children: [
      {
        path: "edit/:robotId",
        name: "robotEdit",
        component: robotEdit,
        meta: {
          title: "로봇관리 | Care Place",
        },
      },
      {
        path: "robotList/:prjId?",
        name: "robotList",
        component: robotList,
        meta: {
          title: "로봇관리 | Care Place",
        },
      },
    ],
  },
  {
    path: "/targets",
    component: Body,
    children: [
      {
        path: "edit/:careTargetId/:serviceType/:prjId/:selectType",
        name: "targetDetail",
        component: targetDetail,
        meta: {
          title: "돌봄받는자 관리 | Care Place",
        },
        props: (route) => ({ params: route.params }),
      },
      {
        path: "targetList/:prjId?",
        name: "targetList",
        component: targetList,
        meta: {
          title: "돌봄받는자 관리 | Care Place",
        },
      },
    ],
  },
  {
    path: "/works",
    component: Body,
    children: [
      {
        path: "careSchedule/:prjId?/:userId?/:dt?",
        name: "careSchedule",
        component: careSchedule,
        meta: {
          title: "일정관리 | Care Place",
        },
      },
      {
        path: "healthRecord",
        name: "healthRecord",
        component: healthRecord,
        meta: {
          title: "건강관리기록 | Care Place",
        },
      },
      {
        path: "careTargetBaseInfo/:scheduleId/:prjId?/:careGiverId?/:dt?/:parentsPage?",
        name: "careTargetBaseInfo",
        component: careTargetBaseInfo,
        meta: {
          title: "돌봄받는자 기초정보 | Care Place",
        },
      },
    ],
  },
  {
    path: "/careGiver",
    component: Body,
    children: [
      {
        path: "careGiverList/:prjId?",
        name: "careGiverList",
        component: careGiverList,
        meta: {
          title: "돌봄자관리 | Care Place",
        },
      },
      {
        path: "careGiverEdit/:userId",
        name: "careGiverEdit",
        component: careGiverEdit,
        meta: {
          title: "돌봄자관리 | Care Place",
        },
      },
    ],
  },
  {
    path: "/notice",
    component: Body,
    children: [
      {
        path: "noticeList/:prjId?",
        name: "noticeList",
        component: noticeList,
        meta: {
          title: "공지사항 | Care Place",
        },
      },
      {
        path: "noticeEdit/:noticeId/:prjFilter?",
        name: "noticeEdit",
        component: noticeEdit,
        meta: {
          title: "공지사항 | Care Place",
        },
      },
    ],
  },
  {
    path: "/monitoring",
    component: Body,
    children: [
      {
        path: "projectMonitor/:prjId?",
        name: "projectMonitor",
        component: projectMonitoring,
        meta: {
          title: "기관현황 | Care Place",
        },
      },
      {
        path: "caregiverMonitor/:prjId?/:dt?",
        name: "caregiverMonitor",
        component: caregiverMonitoring,
        meta: {
          title: "돌봄자현황 | Care Place",
        },
      },
      {
        path: "targetMonitor/:prjId?",
        name: "targetMonitor",
        component: targetMonitoring,
        meta: {
          title: "돌봄받는자현황 | Care Place",
        },
      },
      {
        path: "targetDetailMonitor/:careTargetId/:dt/:prjFilter?/:parentsPage?/:careGiverId?",
        name: "targetDetailMonitor",
        component: targetDetailMonitoring,
        props: true,
        meta: {
          title: "돌봄받는자현황상세 | Care Place",
        },
      },
      {
        path: "/targetListGroup/:areaId",
        name: "targetListGroup",
        component: targetListGroup,
        meta: {
          title: "돌봄받는자 | Care Place",
        },
      },
      {
        path: "/targetListCard/:areaId/:serviceTy",
        name: "targetListCard",
        component: targetListCard,
        meta: {
          title: "구역 별 돌봄자 수 | Care Place",
        },
      },

      {
        path: "/targetListCareGroup/:userId/:prjId",
        name: "targetListCareGroup",
        component: targetListCareGroup,
        meta: {
          title: "돌봄자 별 돌봄받는자 수 | Care Place",
        },
      },
      {
        path: "weeklyReports/:userId?/:prjId?/:parentsPage:?/:dt?",
        name: "weeklyReports",
        component: weeklyReports,
        meta: {
          title: "주간업무일지 | Care Place",
        },
      },
      {
        path: "caregiverDetail/:prjId/:userId/:dt/:parentsPage?",
        name: "caregiverDetail",
        component: caregiverDetail,
        meta: {
          title: "돌봄자현황상세 | Care Place",
        },
      },
      {
        path: "robotMonitor/:prjId?",
        name: "robotMonitor",
        component: robotMonitoring,
        meta: {
          title: "로봇현황 | Care Place",
        },
      },
      {
        path: "robotMonitorDetail/:robotTy/:prjId?/:parentsPage?",
        name: "robotMonitorDetail",
        component: robotMonitorDetail,
        meta: {
          title: "로봇현황목록 | Care Place",
        },
      },
      {
        path: "robotMonitorDetailCard/:robotId",
        name: "robotMonitorDetailCard",
        component: robotMonitorDetailCard,
        meta: {
          title: "로봇현황상세 | Care Place",
        },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 항상 페이지의 맨 위로 스크롤
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  const publicPages = ["/auth/login", "/auth/register_1", "/auth/register_2", "/auth/register_3"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("accessToken");

  if (authRequired && !loggedIn) {
    return next("/auth/login");
  }

  next();
});

export default router;
