<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">기관 검색</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mb-4">
        <div class="row d-flex justify-content-end">
          <div class="col-sm-12 col-md-6">
            <label>기관 </label>
            <input class="form-control" type="text" placeholder="검색" v-model="searchValue" />
          </div>
        </div>
        <EasyDataTable
          table-class-name="custom-data-table custom-modal"
          buttons-pagination
          :search-value="searchValue"
          @click-row="prjSelected"
          :search-field="'prjNm'"
          :headers="Headers"
          rowsPerPage="10"
          :items="Items"
          show-index
        >
          <template #item-prjNm="{ prjNm, prjNm_label }">
            <td :data-label="prjNm_label">
              {{ prjNm }}
            </td>
          </template>
          <template #item-serviceTy="{ serviceTy, serviceTy_label }">
            <td :data-label="serviceTy_label">
              {{ formatServiceTy(serviceTy) }}
            </td>
          </template>
          <template #item-phone="{ phone, phone_label }">
            <td :data-label="phone_label">
              {{ formatPhoneNumber(phone) }}
            </td>
          </template>
        </EasyDataTable>
      </div>
      <div class="modal-footer">
        <button v-if="!getDefaultPrj" class="btn btn-primary" type="button" data-bs-dismiss="modal" @click="prjReset">초기화</button>
        <button id="close-btn" class="btn btn-secondary" type="button" data-bs-dismiss="modal" @click="clearInput">닫기</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import { srchPrjHeader } from "@/data/tableHeaders.js";
import { formatPhoneNumber, formatServiceTy } from "@/utils/common.js";
export default {
  name: "SrchPrjModal",
  data() {
    return {
      searchPrjNm: "",
      prjId: "",
      projects: [],
      formatServiceTy,
      formatPhoneNumber,
      Headers: srchPrjHeader,
      Items: [],
      searchValue: "",
    };
  },
  props: {
    getDefaultPrj: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openSurveyModal() {
      if (this.$refs.surveyModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.surveyModal.showModal(this.surveyData);
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    openCounselModal() {
      if (this.$refs.surveyModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.counselModal.showModal(this.counselData); // show() 메서드 호출
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    openProvideModal() {
      if (this.$refs.provideModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.provideModal.showModal(this.providePlanData); // show() 메서드 호출
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    fetchProjects() {
      const url = `/project/find?`;
      api
        .get(url)
        .then((response) => {
          // console.log("response.data.Projects!!! : ", response.data);
          this.Items = this.setDataLabel(response.data);
          this.projects = response.data; // 회원 데이터 할당
          if ((response.data.length > 0) & this.getDefaultPrj) this.prjSelected(response.data[0]);
        })
        .catch((error) => {
          console.error("기관 목록을 불러오는 중 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "기관 목록을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
    prjSelected(item) {
      // Emit the general event (to maintain compatibility)
      this.$emit("project-selected", item);
      //console.log("공통 item", item);

      // Close the modal
      const closeBtn = document.getElementById("close-btn");
      if (closeBtn) {
        closeBtn.click();
      }
    },
    setDataLabel(items) {
      items.forEach((item) => {
        item.prjNm_label = "기관";
        item.phone_label = "연락처";
        item.serviceTy_label = "서비스유형";
      });
      return items;
    },
    clearInput() {
      this.searchValue = "";
    },
    prjReset() {
      const prjClear = { prjId: "", prjNm: "" };
      this.prjSelected(prjClear);
      this.clearInput();
    },
  },
  mounted() {
    this.fetchProjects(this.currentPage);
    /* if (this.getDefaultPrj) {
            try {
                this.fetchProjects(this.currentPage);
            } catch (error) {
                console.error("기본 프로젝트 정보를 불러오는 중 오류 발생:", error);
            }
        } */
  },
};
</script>
