export const userListHeader = [
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "성명(아이디)",
    value: "userNm",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "권한",
    value: "userRole",
    sortable: true,
  },
  {
    text: "상태",
    value: "delYn",
    sortable: true,
  },
  {
    text: "회원 승인",
    value: "confirmYn",
    sortable: true,
  },
  {
    text: "담당기관 승인",
    value: "prjManage",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];
export const noticeListHeader = [
  {
    text: "중요도",
    value: "importance",
    sortable: true,
  },
  {
    text: "공지 구분",
    value: "noticeScope",
    sortable: true,
  },
  {
    text: "제목",
    value: "title",
    sortable: true,
  },
  {
    text: "작성자",
    value: "userNm",
    sortable: true,
  },
  {
    text: "공지 시작일",
    value: "noticeStart",
    sortable: true,
  },
  {
    text: "공지 종료일",
    value: "noticeEnd",
    sortable: true,
  },
  {
    text: "작성일",
    value: "dt",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];
export const robotListHeader = [
  {
    text: "로봇종류",
    value: "robotType",
    sortable: true,
  },
  {
    text: "로봇 시리얼 넘버",
    value: "robotId",
    sortable: true,
  },
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "상태",
    value: "robotStatus",
    sortable: true,
  },
  {
    text: "특이사항",
    value: "notes",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];

export const robotManageHeader = [
  {
    text: "로봇 종류 코드",
    value: "codeId",
    sortable: true,
    width: "200px",
  },
  {
    text: "로봇 종류 명",
    value: "codeNm",
    sortable: true,
    width: "200px",
  },
  {
    text: "",
    value: "btnArea",
    width: "200px",
  },
];

export const srchPrjHeader = [
  {
    text: "기관명",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "서비스유형",
    value: "serviceTy",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
];

export const srchManagerPrjHeader = [
  {
    text: "",
    value: "checkboxArea",
  },
  {
    text: "기관명",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "서비스유형",
    value: "serviceTy",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "담당기관 승인",
    value: "prjRole",
    sortable: true,
  },
];

export const srchNotExecutionHeader = [
  {
    text: "대상자",
    value: "target",
    sortable: true,
  },
  {
    text: "계획시간",
    value: "planTime",
    sortable: true,
  },
];

export const targetListHeader = [
  {
    text: "서비스유형",
    value: "serviceType",
    sortable: true,
  },
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "성명",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "돌봄자",
    value: "careUsers",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];

export const careGiverListHeader = [
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "성명",
    value: "userNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "담당구역",
    value: "areas",
    sortable: true,
  },
  {
    text: "회원 승인",
    value: "confirmYn",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];

export const caregiverCurrentHeader = [
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "돌봄자",
    value: "userNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "돌봄받는자 수",
    value: "careTargetCnt",
    sortable: true,
  },
  {
    text: "돌봄일정 수",
    value: "stateCnt",
    sortable: false,
  },
  {
    text: "주간업무일지",
    value: "weeklyReports",
    sortable: false,
  },
];

export const robotHistoryHeader = [
  {
    text: "로봇종류",
    value: "robotType",
    sortable: true,
  },

  {
    text: "로봇 시리얼 넘버",
    value: "robotId",
    sortable: true,
  },

  {
    text: "상태",
    value: "robotStatus",
    sortable: true,
  },

  {
    text: "로봇 이력사항",
    value: "robotData",
    sortable: true,
  },
  {
    text: "활동시간",
    value: "activityTime",
    sortable: true,
  },
  {
    text: "등록일", // 기본값
    value: "regDate",
    sortable: true,
  },
];

export const robotCurrentHeader = [
  {
    text: "로봇종류",
    value: "robotType",
    sortable: true,
  },

  {
    text: "로봇 시리얼 넘버",
    value: "robotId",
    sortable: true,
  },

  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },

  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },

  {
    text: "상태",
    value: "robotStatus",
    sortable: true,
  },

  {
    text: "특이사항",
    value: "notes",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];

export const prjListHeader = [
  {
    text: "기관명",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "서비스 유형",
    value: "serviceType",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "이메일",
    value: "email",
    sortable: true,
  },
  {
    text: "기관 상태",
    value: "delYn",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  },
];

export const healthRecordHeader = [
  {
    text: "상태",
    value: "importance",
    // sortable: true,
    // width: 10,
  },
  {
    text: "이름",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
    //width: 100,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "맥박(회/분)",
    value: "pulse",
    sortable: true,
    //width: 120,
  },
  {
    text: "체온(℃)",
    value: "bodyTemperature",
    sortable: true,
    // width: 120,
  },
  {
    text: "산소포화도(%)",
    value: "oxygenSaturation",
    sortable: true,
    // width: 120,
  },
  {
    text: "혈압(수축기)",
    value: "systolic",
    sortable: true,
    // width: 120,
  },
  {
    text: "혈압(이완기)",
    value: "diastolic",
    sortable: true,
    // width: 120,
  },

  {
    text: "걸음수",
    value: "steps",
    sortable: true,
    // width: 120,
  },
  {
    text: "담당자(작성자)",
    value: "careUsers",
    sortable: true,
    // width: 100,
  },

  {
    text: "",
    value: "btnArea",
    // width: 250,  // 버튼 영역은 좁게 설정
  },
];

export const srchTargetHeader = [
  {
    text: "",
    value: "checkboxArea",
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "성명",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "돌봄자",
    value: "careUsers",
    sortable: true,
  },
];

export const selectTargetHeader = [
  {
    text: "",
    value: "checkboxArea",
  },
  {
    text: "성명",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "나이",
    value: "birthDt",
    sortable: true,
  },
];

export const managerMonitorHeader = [
  {
    text: "성명",
    value: "userNm",
    sortable: true,
  },

  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
];

export const careGiverMonitorHeader = [
  {
    text: "성명",
    value: "targetNm",
    sortable: true,
  },

  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "담당구역",
    value: "areas",
    sortable: true,
  },
];

export const targetMonitorHeader = [
  {
    text: "서비스 유형",
    value: "serviceType",
    sortable: true,
  },
  {
    text: "성명",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "돌봄자",
    value: "careUsers",
    sortable: true,
  },
  /* {
    text: "돌봄로봇",
    value: "robot",
    sortable: true,
  },
  {
    text: "",
    value: "btnArea",
  }, */
];

export const targetMonitoringHeader = [
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "이름",
    value: "careTargetNm",
    sortable: true,
  },

  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "맥박(회/분)",
    value: "pulse",
    sortable: true,
  },

  {
    text: "체온(℃)",
    value: "bodyTemperature",
    sortable: true,
  },
  {
    text: "산소포화도(%)",
    value: "oxygenSaturation",
    sortable: true,
  },
  {
    text: "혈압(수축기)",
    value: "systolic",
    sortable: true,
  },
  {
    text: "혈압(이완기)",
    value: "diastolic",
    sortable: true,
  },
  {
    text: "걸음수",
    value: "steps",
    sortable: true,
  },

  {
    text: "보호자 이름",
    value: "gNm",
    sortable: true,
  },
  {
    text: "관계",
    value: "gRelation",
    sortable: true,
  },
  {
    text: "돌봄자",
    value: "careUsers",
    sortable: true,
  },
  /*  {
    text: "",
    value: "btnArea",
  }, */
];

export const dayScheduleHeader = [
  {
    text: "돌봄받는자",
    value: "careTargetNm",
    // sortable: true,
  },
  {
    text: "제공 서비스",
    value: "activityNotes",
    // sortable: true,
  },
  {
    text: "서비스시간",
    value: "executionTime",
    // sortable: true,
  },
  {
    text: "상태",
    value: "state",
    // sortable: true,
  },
];

export const testHeader = [
  {
    text: "돌봄받는자",
    value: "targetNm",
    sortable: true,
  },
  {
    text: "서비스",
    value: "service",
    sortable: true,
  },
  {
    text: "계획시간",
    value: "planTime",
    sortable: true,
  },
  {
    text: "실제 실행시간",
    value: "executionTime",
    sortable: true,
  },
];

export const targetByCareGiverHeader = [
  {
    text: "성명",
    value: "careTargetNm",
    sortable: true,
  },
  {
    text: "구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
];

export const srchCareGiverHeader = [
  {
    text: "기관",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "성명",
    value: "userNm",
    sortable: true,
  },
  {
    text: "성별",
    value: "gender",
    sortable: true,
  },
  {
    text: "생년월일",
    value: "birthDt",
    sortable: true,
  },
];

export const userAreaHeader = [
  {
    text: "상위 구역",
    value: "parentsNm",
    sortable: true,
  },
  {
    text: "하위 구역",
    value: "areaNm",
    sortable: true,
  },
  {
    text: "돌봄받는자 수",
    value: "careTargetCnt",
    sortable: true,
  },
];

export const prjManageHeader = [
  {
    text: "기관명",
    value: "prjNm",
    sortable: true,
  },
  {
    text: "서비스유형",
    value: "serviceTy",
    sortable: true,
  },
  {
    text: "연락처",
    value: "phone",
    sortable: true,
  },
  {
    text: "담당기관 승인",
    value: "prjRole",
    sortable: true,
  },
];
