<template>
  <div>
    <Breadcrumbs main="대시보드" title="Dashboard" />
    <div class="container-fluid">
      <div class="row size-column">
        <!-- <div class="col-xxl-10 col-md-12 box-col-8 grid-ed-12"> -->
        <div class="col-xxl-12 col-md-12 box-col-8 grid-ed-12">
          <div class="row mb-3">
            <!-- v-if="userRole == '0'" -->
            <div class="col-xxl-3 col-md-12 ms-auto">
              <label>기관 </label>
              <div class="input-group" data-bs-toggle="modal" data-bs-target="#srchPrjMd">
                <input class="form-control" placeholder="기관명" type="text" v-model="prjNm" readonly />
                <button type="button" class="input-group-text btn btn-primary input-group-search">
                  <i class="icofont icofont-ui-search"></i>
                </button>
              </div>
              <div class="modal fade" id="srchPrjMd" tabindex="-1" role="dialog" aria-hidden="true">
                <srchCommonPrjModal @project-selected="handleProjectSelection" :getDefaultPrj="true" @close="resetScroll" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-xxl-12 col-md-12 box-col-8 grid-ed-12 order-0 custom-order">
              <AllRobotStatusCntCard ref="allRobotStatusCntCard" :prjId="prjId" />
              <!-- Total Robots Status -->
            </div>
            <!-- <div class="col-sm-12 col-xxl-4 col-md-12 box-col-4 grid-ed-12 order-2 custom-order">
                            <NoticesCard /> 공지사항 헤더쪽에서 흐르고 있으므로 일단 주석처리

                        </div> -->
            <div class="col-sm-12 col-xxl-12 col-md-12 box-col-12 grid-ed-12 order-1 custom-order">
              <CareRobotStatusCard ref="careRobotStatusCard" :prjId="prjId" :serviceTy="serviceTy" />
              <br />
              <!-- Part Robots Status -->
            </div>
            <div class="col-sm-12 col-xxl-12 col-md-12 box-col-12 grid-ed-12 order-3 custom-order">
              <CareTargetListCard :prjId="prjId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.scrollable-y {
  max-height: 190px;
  /* 485px; */
  /* 또는 필요한 높이 */
  overflow-y: auto;
  /* 컨텐츠 높이가 컨테이너 높이를 초과하는 경우 스크롤바 표시 */
}

@media (min-width: 1801px) {
  .custom-order {
    order: 0 !important;
  }
}

@media (max-width: 1800px) {
  .order-0.custom-order {
    order: -1 !important;
  }

  .order-2.custom-order {
    order: 6 !important;
  }

  .order-1.custom-order {
    order: 1 !important;
  }

  .order-3.custom-order {
    order: 2 !important;
  }
}
</style>
<script>
import AllRobotStatusCntCard from "./main/AllRobotStatusCntCard.vue";
//import NoticesCard from "./main/NoticesCard.vue";
import CareTargetListCard from "./main/CareTargetListCard.vue";
import CareRobotStatusCard from "./main/CareRobotStatusCard.vue";
import srchCommonPrjModal from "@/modal/srchCommonPrjModal.vue";
export default {
  components: {
    AllRobotStatusCntCard,
    //NoticesCard,
    CareRobotStatusCard,
    CareTargetListCard,
    srchCommonPrjModal,
  },
  data() {
    return {
      userRole: "",
      prjId: "",
      prjNm: "",
      serviceTy: null, // Track service type
    };
  },
  computed: {
    userRole() {
      let user = JSON.parse(localStorage.getItem("User"));
      let userRole = user.userRole;
      return userRole;
    },
  },
  methods: {
    handleProjectSelection(project) {
      this.serviceTy = project.serviceTy;
      this.prjId = project.prjId;

      this.prjNm = project.prjNm;
      this.prjNmFilter = project.prjNm;

      this.$refs.allRobotStatusCntCard.fetchTargets(this.serviceTy, this.prjId);
      this.$refs.careRobotStatusCard.fetchTargets(this.serviceTy, this.prjId);
    },
  },
  async mounted() {
    let userInfo = JSON.parse(localStorage.getItem("User"));
    let userRole = userInfo.userRole;
    if (userRole === "3") {
      this.serviceTy = userInfo.serviceTy;
      this.prjId = userInfo.prjId;
      // this.$refs.allRobotStatusCntCard.fetchTargets(this.serviceTy, this.prjId);
      this.$refs.careRobotStatusCard.fetchTargets(this.serviceTy, this.prjId);
    }
  },
};
</script>
